@use 'src/src/app/scss/abstracts' as *;

.container {
  $block-name: &;

  @include container;

  &--size-infinity {
    @include container(none);
  }

  &--size-2xs {
    @include container(870px);
  }

  &--size-xs {
    @include container(1110px);
  }

  &--size-s {
    @include container(1300px);
  }

  &--size-m {
    @include container;
  }

  &--size-l {
    //@include container(1700px);
  }

  &--size-xl {
    //@include container(1820px);
  }

  &--without-paddings {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
